import React, { useState, useRef } from "react"
import axios from 'axios';

import Emoji from '../helpers/Emoji'
import queryString from 'query-string'

import * as SignUpStyles from "../SignUp/signup.module.scss"
import { Link } from "gatsby";

import { useForm } from "react-hook-form";

import { navigate } from "gatsby"
import Spinner from "../elements/Spinner";
import Password from "../elements/PasswordField";

const ResetPasswordForm = (props) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [postErrors, setPostErrors] = useState(false);
	
  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onBlur'
  });

	const search = props.location.search ? queryString.parse(props.location.search) : false;

  const resetToken = search ? search.resetToken : '';
  const resetEmail = search ? search.resetEmail : '';

  const password = useRef({});
  password.current = watch("password", "");


  const { errors } = formState;

  const onSubmit = data => {
    setIsSubmitting(true);


		const resetData = {
			email: decodeURIComponent(resetEmail),
			password: data.password,
			confirmPassword: data.confirmPassword,
			resetToken: encodeURIComponent(resetToken)
		}


    axios.post(`${process.env.GATSBY_APIURL}/a/Account/ResetPassword`, resetData)
    .then((response) => {
      setIsSubmitting(false);
			navigate("/login")
    })
    .catch(function (error) {
      setIsSubmitting(false);
      if (error.response) {
				if (error.response.status === 400) setPostErrors(error.response.data.modelState);

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      }
      //console.log(error.config);
    });
  };
  const onError = (errors, e) => {
    setIsSubmitting(false);
    //console.log(errors, e);
  }


  let postErrorArray = [];
  if (postErrors) {

    for (const [key, value] of Object.entries(postErrors)) {
      postErrorArray.push(`${value}`);
    }
  }

  return (
    <section className={SignUpStyles.signUp}>
      <div className={SignUpStyles.innerDiv}>
        <div className="component">
          <h1>Reset Password</h1>
          <div className={SignUpStyles.subText}>Please enter a new password below.  <Emoji symbol="💪" label="Muscles" /> </div>

          {!isSuccess ? 
					<form  id="reset-password-form" onSubmit={handleSubmit(onSubmit, onError)}>

            <div className={SignUpStyles.emailForm}>
              <div className={SignUpStyles.formDiv}>
                
								<div className={SignUpStyles.inputWrap}>
									<Password placeholder="Password" id="new-password"  autoComplete="new-password" register={
										register("password", {
											required: {
												value: true,
												message: 'Password is required'
											},
											minLength: {
												value: 6,
												message: 'Please ensure your password has at least 6 charaters'
											}
										}
										)} error={errors.password} validationMessage="A password is required and has to be at least 6 charaters long" />
								</div>
								<div className={SignUpStyles.inputWrap}>
										<Password placeholder="Confirm Password" id="new-password-confirm" autoComplete="new-password" register={register("confirmPassword", {
											required: {
												value: true,
												message: 'Password confirmation is required'
											},
											minLength: {
												value: 6,
												message: 'Please ensure your password has at least 6 charaters'
											},
											validate: {
												match: (value) => {
													// console.log(value);
													// console.log(password)
													return value === password.current || "Password's do not match"
												},
											}
										})
									} validationMessage="Passwords do not match" error={errors.passwordConf} />
								</div>

								{postErrorArray.length > 0 &&
										<div className={SignUpStyles.postErrors}>
											{
												postErrorArray.map((item, index) => {
													if (Array.isArray(item)) {
														return (
															item.map((item2, index2) => (
																<div key={`child-${index2}`}>{item2}</div>
															))
														)
													}else{
														return (
														<div key={index}>{item}</div>
														)
													}
												})
											}
										</div>
									}


                <div className={`${SignUpStyles.inputWrap}`}>
                  <button type="submit" className={SignUpStyles.button}><span className={SignUpStyles.buttonInner}>{isSubmitting && <Spinner />} Reset Password &rarr;</span></button>
                </div>

              </div>
            </div>
          </form>
					:
					<div>Please check your email for a reset password link</div>

					}

          <div className={SignUpStyles.disclaimer}>
            By creating a loaded account agree to the <Link to="/end-user-licence-agreement">End User Licence Agreement</Link> and <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResetPasswordForm;
