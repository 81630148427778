import React from "react"
import Base from "../components/templates/Base"
import ResetPasswordForm from "../components/Login/ResetPasswordForm"

const ResetPassword = ({ location }) => {
  return (
    <Base>
      <ResetPasswordForm location={location} />
    </Base>
  );
};

export default ResetPassword;